<template>
    <div>
        <el-breadcrumb separator="/" class="student-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/index' }">店铺选品</el-breadcrumb-item>
            <el-breadcrumb-item>商品采购</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page-title flex justify-between">
            <span>商品采购</span>
            <span class="balance">
                采购余额：<span class="iconfont">&yen;</span>
                <span v-if="purchaseAmount">{{purchaseAmount}}</span>
                <span v-else>0</span>
            </span>
        </div>
        <div class="goodsPurchase-container">
            <div class="goodPurchase-main">
                <div class="goodPurchase-item item-left">
                    <div class="item-head">
                        <div class="head-tab">采购入库</div>
                        <div class="head-operate">
                            <el-button size="medium" @click="addBtn(null)">添加</el-button>
                            <el-button size="medium" @click="delChooseBtn">删除</el-button>
                        </div>
                    </div>
                    <el-table :data="libraryList" border style="width: 100%;" size="medium" show-summary
                              class="library-table" @selection-change="selectChange" :summary-method="getSummaries"
                              :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#666', background: '#F5F5F5'}"
                              :cell-style="{fontSize: '14px',color: '#333'}">
                        <el-table-column type="index" align="center" label="编号" width="50"></el-table-column>
                        <el-table-column type="selection" align="center" width="50"></el-table-column>
                        <el-table-column align="center" label="品牌" min-width="125">
                            <template slot-scope="scope">
                                <p class="text-overflow" v-if="Number(scope.row.brand_type) === 1" :title="`一线品牌${scope.row.parent_name}`">一线品牌{{scope.row.parent_name}}</p>
                                <p class="text-overflow" v-else-if="Number(scope.row.brand_type) === 2" :title="`二线品牌${scope.row.parent_name}`">二线品牌{{scope.row.parent_name}}</p>
                                <p class="text-overflow" v-else-if="Number(scope.row.brand_type) === 3" :title="`三线品牌${scope.row.parent_name}`">三线品牌{{scope.row.parent_name}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品">
                            <template slot-scope="scope">
<!--                                <router-link :to="{path: '/student/operationpromotion/commoditylist/treaddetail', query: {id: scope.row.commodity_id}}" target="_blank"-->
<!--                                             class="good-title text-overflow" :title="scope.row.name">{{scope.row.name}}</router-link>-->
                                <div class="good-title text-overflow" :title="scope.row.name" @click="toDetail(scope.row, 'library')">{{scope.row.name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="number" align="center" label="数量"></el-table-column>
                        <el-table-column prop="unit_price" align="center" label="单位价格"></el-table-column>
                        <el-table-column align="center" label="合计金额">
                            <template slot-scope="scope">
<!--                                <span>{{Number(scope.row.number) * Number(scope.row.unit_price)}}</span>-->
                                <span>{{calculate(Number(scope.row.number), Number(scope.row.unit_price), '*')}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="110">
                            <template slot-scope="scope">
                                <el-link :underline="false" class="link-blue-light" @click="editBtn(scope.row)">编辑</el-link>
                                <el-link :underline="false" type="danger" @click="delBtn(scope.row, scope.$index)">删除</el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="goodPurchase-item item-right">
                    <div class="item-head">
                        <div class="head-tab">供应信息</div>
                        <div class="head-operate">
                            <el-select v-model="chooseBrand" size="medium" placeholder="选择品牌" @change="toggleBrand" style="width: 120px">
                                <el-option v-for="brandItem in brandList" :key="`brandList_${brandItem.name}`" :label="brandItem.name" :value="brandItem.id"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <el-table :data="supplyList" border style="width: 100%;" size="medium"
                              :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#666', background: '#F5F5F5'}"
                              :cell-style="{fontSize: '14px',color: '#333'}">
                        <el-table-column type="index" align="center" label="编号" width="50"></el-table-column>
                        <el-table-column align="center" label="品牌" min-width="125">
                            <template slot-scope="scope">
                                <p class="text-overflow" v-if="Number(scope.row.brand_type) === 1" :title="`一线品牌${scope.row.parent_name}`">一线品牌{{scope.row.parent_name}}</p>
                                <p class="text-overflow" v-else-if="Number(scope.row.brand_type) === 2" :title="`二线品牌${scope.row.parent_name}`">二线品牌{{scope.row.parent_name}}</p>
                                <p class="text-overflow" v-else-if="Number(scope.row.brand_type) === 3" :title="`三线品牌${scope.row.parent_name}`">三线品牌{{scope.row.parent_name}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品">
                            <template slot-scope="scope">
<!--                                <router-link :to="{path: '/student/operationpromotion/commoditylist/treaddetail', query: {id: scope.row.id}}" target="_blank"-->
<!--                                             class="good-title text-overflow" :title="scope.row.name">{{scope.row.name}}</router-link>-->
                                <div class="good-title text-overflow" :title="scope.row.name" @click="toDetail(scope.row, 'supply')">{{scope.row.name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodData[0].costprice" align="center" label="成本价"></el-table-column>
                        <el-table-column prop="goodData[0].marketvalue" align="center" label="市场价"></el-table-column>
                        <el-table-column label="操作" align="center" width="80">
                            <template slot-scope="scope">
                                <el-link :underline="false" class="link-blue-light" @click="addBtn(scope.row)">添加</el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="pager-center"
                                   :current-page="supplyPages.currentPageNum"
                                   :page-size="supplyPages.eachPageNum"
                                   :total="supplyPages.total"
                                   layout="prev, pager, next, total"
                                   @current-change="supplyCurrentChange">
                    </el-pagination>
                </div>
            </div>
            <div class="bottom-btn">
                <el-button class="btn-red" @click="purchaseBtn">采购</el-button>
            </div>

            <!--        添加入库， 编辑弹窗-->
            <el-dialog :title="dialogTitle" :visible.sync="addDialog" width="420px" @close="resetForm()" :close-on-click-modal="false">
                <el-form :model="addForm" ref="addForm" :rules="addRules" label-position="right" label-width="100px">
                    <el-form-item label="采购商品" prop="name">
                        <el-select v-model="addForm.name" placeholder="请选择商品" @change="toggleGoods" clearable style="width: 100%">
                            <el-option v-for="supplyItem in supplyList" :key="`supplyList_${supplyItem.id}`" :label="supplyItem.name" :value="supplyItem.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="采购数量" prop="number">
                        <el-input v-model.number="addForm.number" placeholder="请输入采购数量"></el-input>
                    </el-form-item>
                    <el-form-item label="单价" prop="unit_price">
                        <el-input v-model="addForm.unit_price" disabled placeholder="请输入单价"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="medium" @click="addDialog = false">取 消</el-button>
                    <el-button size="medium" class="btn-red" @click="addToForm('addForm')">确 定</el-button>
                </div>
            </el-dialog>

<!--            采购提示框-->
            <el-dialog title="提示" :visible.sync="purchasePromptDialog" width="600px">
                <div>
                    <p>1.申请一线品牌的店铺不可在进购同类目下的二线品牌、三线品牌。</p>
                    <p>2.申请二线品牌的店铺可进购同类目下的三线品牌，申请三线品牌的店铺同理。</p>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="medium" @click="purchasePromptDialog = false">取 消</el-button>
                    <el-button size="medium" class="btn-red" @click="cantPurChase">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="mask-cover" v-if="competitionType === 1 && (Number(menusList[0].children[0].status) === 0 || Number(menusList[0].children[1].status) === 0)">
            <div class="prompt">
                <i class="iconfont">&#xe724;</i>请先完成数据分析
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        data() {
            return {
                purchaseAmount: null, // 采购余额
                amountTotal: null, // 合计金额
                libraryList: [],
                // libraryPages: {
                //     currentPageNum: 1,
                //     eachPageNum: 10,
                //     total: 0,
                // },
                chooseBrand: '',
                brandList: [
                    {
                        id: 1,
                        brandtype: '1',
                        name: '一线品牌',
                    },
                    {
                        id: 2,
                        brandtype: '2',
                        name: '二线品牌',
                    },
                    {
                        id: 3,
                        brandtype: '3',
                        name: '三线品牌',
                    },
                ],
                supplyList: [],
                supplyPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 10,
                },
                // S 添加弹窗
                dialogTitle: '',
                addDialog: false,
                distinguishBtn: '',
                addForm: {
                    brand_type: '', // 品牌类型1
                    parent_name: '', // 品牌名
                    image: '', // 商品图片1
                    name: '', // 商品名1
                    number: '', // 数量1
                    unit_price: '', // 单价1
                    marketvalue: '', // 市场价1
                    total_amount: '', // 总价1
                    commodity_id: '', // 商品编号1
                    click: '', // 点击1
                    conversion: '', // 转化1
                    good_info: '', // 单条商品信息1
                    goodInfo: '', // 商品属性
                },
                addRules: {
                    name: [
                        { required: true, message: '请选择商品', trigger: 'change' },
                    ],
                    number: [
                        { required: true, type: 'number', message: '请输入采购数量', trigger: 'blur' },
                    ],
                    unit_price: [
                        { required: true, message: '请输入单价', trigger: 'blur' },
                    ],
                },
                currentRow: null,
                // E 添加弹窗

                // 采购提示弹窗
                purchasePromptDialog: false,
                selectArr: [],
                alreadyLibraryList: [],
                //菜单
                submitStatus: null,
                competitionType: this.$lockr.get('competition_type')
            }
        },
        computed: {
            menusList() {
                let _menus = this.$store.getters.dataMenuList;
                if (Number(_menus[0].children[1].status) === 2 && Number(_menus[0].children[2].status) === 0) {
                    this.operation_updateStatus(0, 2, 1);
                }
                return _menus;
            }
        },
        created() {
            this.chooseBrand = this.brandList[0].id
            //如果是比賽,获取菜单
            /*if (this.$common.isCompetion()) {
                this.setDataMenusList();
                let menus = this.menusList;
                let currentStatus = menus[0].children[2].status;
                let perStatus = menus[0].children[1].status;
                let lastStatus = menus[2].children[3].status;
                if (Number(currentStatus) === 0 && Number(perStatus) === 2) {
                    this.operation_updateStatus(0, 2, 1);
                    this.submitStatus = 2;
                }
                if (Number(currentStatus) === 1 || Number(currentStatus) === 2 || Number(perStatus) === 2) {
                    this.submitStatus = 2;
                }
                if (Number(lastStatus) === 2) {
                    this.submitStatus = 2;
                }
            }*/

           /* this.$http.axiosGet(this.$api.data_userOpList, (res) => {
                if (res.code === 200) {
                    this.menus = res.data;
                    //更新菜单

                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                console.log(err);
            })*/

        },
        mounted() {
            this.getPurchaseAmount()
            this.getSupplyList()
            this.getLibraryList()
        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取采购余额
            getPurchaseAmount() {
                this.$http.axiosGet(this.$api.storeSelectionAmount, (res) => {
                    if (res.code === 200) {
                        this.purchaseAmount = Number(res.data.amount);
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取已采购列表
            getLibraryList() {
                this.$http.axiosGet(this.$api.storeSelectionAddGoods, (res) => {
                    if (res.code === 200) {
                        this.alreadyLibraryList = res.data.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取供应列表
            getSupplyList() {
                let params = {
                    page: this.supplyPages.currentPageNum,
                    pageSize: this.supplyPages.eachPageNum,
                    brandtype: Number(this.chooseBrand),
                }
                this.$http.axiosGetBy(this.$api.storeSelectionSupplyList, params, (res) => {
                    if (res.code === 200) {
                        this.supplyList = res.data.data
                        this.supplyPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 供应list 分页切换
            supplyCurrentChange(val) {
                this.supplyPages.currentPageNum = val;
                this.getSupplyList()
            },
            // 切换品牌
            toggleBrand(val) {
                this.getSupplyList()
            },
            // 左侧添加，右侧list添加
            addBtn(row) {
                if (row === null) {
                    // console.log('左侧添加', row)
                } else {
                    this.addForm.brand_type = row.brand_type
                    this.addForm.parent_name = row.parent_name
                    this.addForm.image = row.image
                    this.addForm.name = row.name
                    // this.addForm.number = Number(row.number)
                    this.addForm.unit_price = Number(row.goodData[0].costprice)
                    this.addForm.marketvalue = Number(row.goodData[0].marketvalue)
                    // this.addForm.total_amount = Number(row.number) * Number(row.goodData[0].costprice)
                    this.addForm.commodity_id = row.id
                    this.addForm.click = Number(row.goodData[0].click).toFixed(2)
                    this.addForm.conversion = Number(row.goodData[0].conversion).toFixed(2)
                    this.addForm.good_info = row
                    this.addForm.goodInfo = row.goodInfo
                }
                this.dialogTitle = '添加采购商品'
                this.distinguishBtn = 'add'
                this.addDialog = true
            },
            // 切换商品
            toggleGoods(val) {
                let obj = {};
                obj = this.supplyList.find((item) => {
                    return item.id === val
                })
                this.addForm.brand_type = obj.brand_type
                this.addForm.parent_name = obj.parent_name
                this.addForm.image = obj.image
                this.addForm.name = obj.name
                // this.addForm.number = Number(obj.number)
                this.addForm.unit_price = Number(obj.goodData[0].costprice)
                this.addForm.marketvalue = Number(obj.goodData[0].marketvalue)
                // this.addForm.total_amount = Number(obj.number) * Number(obj.goodData[0].costprice)
                this.addForm.commodity_id = obj.id
                this.addForm.click = Number(obj.goodData[0].click).toFixed(2)
                this.addForm.conversion = Number(obj.goodData[0].conversion).toFixed(2)
                this.addForm.good_info = obj
                this.addForm.goodInfo = obj.goodInfo
            },
            // 编辑
            editBtn(row) {
                this.dialogTitle = '编辑采购商品'
                this.distinguishBtn = 'edit'
                this.addDialog = true
                this.currentRow = JSON.parse(JSON.stringify(row)) // 编辑
                this.addForm = row // 确定的时候修改
            },
            // 添加到库确定
            addToForm(formName) {
                if (this.addForm.number < 1) {
                    this.$message.warning('采购数量必须大于等于1')
                    return
                }

                // 只能采购五款商品
                let alreadyId = this.alreadyLibraryList.map(item => item.commodity_id)
                let libraryId = this.libraryList.map(item => item.commodity_id)
                let mergeId = [...new Set(alreadyId.concat(libraryId))]
                if (mergeId.includes(this.addForm.commodity_id) || mergeId.length < 5) {
                    // this.$message.success('该商品可添加')
                } else {
                    return this.$message.warning('只能采购五款商品')
                }

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let tmpObj = {
                                brand_type: this.addForm.brand_type, // 品牌类型
                                parent_name: this.addForm.parent_name, // 品牌名
                                image: this.addForm.image, // 商品名
                                name: this.addForm.name, // 商品名
                                number: Number(this.addForm.number), // 数量
                                unit_price: Number(this.addForm.unit_price), // 单价
                                marketvalue: Number(this.addForm.marketvalue), // 单价
                                // total_amount: Number(this.addForm.number) * Number(this.addForm.unit_price), // 总价
                                total_amount: this.calculate(Number(this.addForm.number), Number(this.addForm.unit_price), '*'), // 总价
                                commodity_id: this.addForm.commodity_id, // 商品编号
                                click: Number(this.addForm.click), // 点击
                                conversion: Number(this.addForm.conversion), // 转化
                                good_info: this.addForm.good_info, // 单条商品信息
                                goodInfo: this.addForm.goodInfo, // 商品信息
                            }
                            let containTmp = this.libraryList.find(item => {
                                return item.commodity_id === tmpObj.commodity_id
                            })
                            if (containTmp) {
                                // containTmp.number = Number(containTmp.number) + Number(tmpObj.number)
                                containTmp.number = this.calculate(Number(containTmp.number), Number(tmpObj.number), '+')
                            } else {
                                this.libraryList.push(tmpObj)
                                // this.libraryPages.total++
                            }
                            this.$message.success('添加成功')
                            this.addDialog = false
                            this.resetForm()
                        } else if (this.distinguishBtn === 'edit') {
                            this.addForm = {...this.addForm, ...this.currentRow}
                            this.$message.success('编辑成功')
                            this.addDialog = false
                            this.resetForm()
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 删除
            delBtn(row, index) {
                this.$confirm('是否删除该商品，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning',
                }).then(() => {
                    this.libraryList.splice(index, 1)
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            // 采购
            purchaseBtn() {
                let allBrandId = this.brandList.map(item => Number(item.brandtype)).sort() // 品牌的类型数组
                let goodsBrandId = this.libraryList.map(item => Number(item.brand_type)) // 采购的商品的品牌类型数组
                let alreadyListID = this.alreadyLibraryList.map(item => Number(item.brand_type)) // 已采购的商品的品牌类型数组

                if (this.libraryList.length === 0) {
                    return this.$message.warning('请选择商品')
                }
                if (this.amountTotal > this.purchaseAmount) {
                    return this.$message.warning('合计金额大于采购金额，请重新筛选商品')
                }
                // 如果已经采购过商品
                if (this.alreadyLibraryList.length > 0) {
                    if (alreadyListID.includes(allBrandId[0])) { // 如果已采购过的商品包含一线品牌
                        if (goodsBrandId.includes(allBrandId[1]) || goodsBrandId.includes(allBrandId[2])) { // 新添加的采购列表包含二线三线
                            this.$message({type: 'warning', customClass: 'zIndex9999', duration: 5000,
                                message: '您的采购列表里已经包含一线品牌，请继续采购一线品牌'})
                            this.purchasePromptDialog = true // 包含1，2，3品牌不能采购
                            return
                        } else {
                            this.surePurChase() // 只包含1可以采购
                        }
                    } else { // 如果已采购过的商品包含二线，三线品牌
                        if (goodsBrandId.includes(allBrandId[0])) { // 新添加的采购列表包含一线
                            this.$message({type: 'warning', customClass: 'zIndex9999', duration: 5000,
                                message: '您的采购列表里已经包含二线或三线或二三线品牌，请继续采购二线，三线品牌'})
                            this.purchasePromptDialog = true // 包含1，2，3品牌不能采购
                            return
                        } else {
                            this.surePurChase() // 包含2，3，23可以采购
                        }
                    }
                }
                // 未采购的情况下
                if (this.libraryList.length > 0) {
                    if (goodsBrandId.includes(allBrandId[0])) { // 包含1
                        if (goodsBrandId.includes(allBrandId[1]) || goodsBrandId.includes(allBrandId[2])) { // 包含2，3
                            this.purchasePromptDialog = true // 包含1，2，3品牌不能采购
                        } else { // 只包含1
                            this.surePurChase() // 只包含1可以采购
                        }
                    } else { // 包含2，3
                        this.surePurChase() // 包含2，3，23可以采购
                    }
                }
            },
            // 重置表单
            resetForm() {
                this.addForm = {
                    brand_type: '', // 品牌类型
                    parent_name: '', // 品牌名
                    image: '', // 商品图片
                    name: '', // 商品名
                    number: '', // 数量
                    unit_price: '', // 单价
                    marketvalue: '', // 市场价
                    total_amount: '', // 总价
                    commodity_id: '', // 商品编号
                    click: '', // 点击
                    conversion: '', // 转化
                    good_info: '', // 单条商品信息
                    goodInfo: '', // 商品信息
                }
            },
            // 选择删除list
            selectChange(selection) {
                this.selectArr = selection
            },
            // 删除选择的
            delChooseBtn() {
                if (this.selectArr.length === 0) {
                    this.$message.warning('请选择商品')
                    return false
                }
                this.$confirm('是否删除所选择的商品吗，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning',
                }).then(() => {
                    this.libraryList = this.libraryList.filter(item => {
                        let arrTmp = this.selectArr.map(selectItem => selectItem.commodity_id)
                        return arrTmp.includes(item.commodity_id) === false
                    })
                    this.$message.success('删除成功')
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            // 确定采购
            surePurChase() {
                this.$confirm('是否确定采购该商品列表？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning',
                }).then(() => {
                    let formData = new FormData()
                    formData.append('goods', JSON.stringify(this.libraryList))
                    this.$http.axiosPost(this.$api.storeSelectionAddGoods, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getPurchaseAmount()
                            this.getSupplyList()
                            this.getLibraryList()
                            this.libraryList = [];
                            if (this.$common.isCompetion()) {
                                let menus = this.menusList;
                                if (Number(menus[0].children[2].status) !== 2) {
                                    this.operation_updateStatus(0,2,2);
                                }
                            }
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消采购')
                });
            },
            // 不能采购
            cantPurChase() {
                this.$message.warning('采购失败，请重新筛选商品')
                this.purchasePromptDialog = false
            },
            // 跳至商品详情页
            toDetail(row, type) {
                localStorage.setItem('selectionGoodInfo', JSON.stringify(row))
                let idTmp = null
                if (type === 'supply') {
                    idTmp = row.id
                } else {
                    idTmp = row.commodity_id
                }
                let routeUrl = this.$router.resolve({
                    path: '/student/operationpromotion/purchasedetail',
                    query: {
                        id: idTmp,
                        type: type
                    }
                });
                window.open('/' + routeUrl.href, '_blank')
            },
            // 表格计算合计金额 要再看看对不对 // 貌似没问题
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    // let allPrice =  data.map(item => item.total_amount = Number(item.number) * Number(item.unit_price))
                    let allPrice =  data.map(item => item.total_amount = this.calculate(Number(item.number), Number(item.unit_price), '*'))
                    // if ([4,5,6].includes(index)) {
                    //     if (index === 6) {
                    //         sums[6] = allPrice.reduce((prev, curr) => {
                    //             const value = Number(curr);
                    //             if (!isNaN(value)) {
                    //                 return prev + curr;
                    //             } else {
                    //                 return prev;
                    //             }
                    //         }, 0);
                    //     }
                    //     sums[index] = values.reduce((prev, curr) => {
                    //         const value = Number(curr);
                    //         if (!isNaN(value)) {
                    //             return prev + curr;
                    //         } else {
                    //             return prev;
                    //         }
                    //     }, 0);
                    // }
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                // return prev + curr;
                                return this.calculate(prev, curr, '+');
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else if (index === 6) {
                        sums[index] = allPrice.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                // return prev + curr;
                                return this.calculate(prev, curr, '+');
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = '';
                    }
                });
                this.amountTotal = Number(sums[6])
                return sums;
            },
            // js计算精度丢失
            toInteger(floatNum) {
                const ret = { times: 1, num: 0 }
                const isNegative = floatNum < 0
                if (Math.floor(floatNum) === floatNum) {
                    ret.num = floatNum
                    return ret
                }
                const strfi = String(floatNum)
                const dotPos = strfi.indexOf('.')
                const len = strfi.substr(dotPos + 1).length
                const times = Math.pow(10, len)
                let intNum = parseInt(Math.abs(floatNum) * times + 0.5, 10)
                ret.times = times
                if (isNegative) {
                    intNum = -intNum
                }
                ret.num = intNum
                return ret
            },
            calculate(a, b, op) {
                const o1 = this.toInteger(Number(a))
                const o2 = this.toInteger(Number(b))
                const n1 = o1.num
                const n2 = o2.num
                const t1 = o1.times
                const t2 = o2.times
                const max = t1 > t2 ? t1 : t2
                let result = null
                switch (op) {
                    case '+':
                        if (t1 === t2) {
                            // 两个小数位数相同
                            result = n1 + n2
                        } else if (t1 > t2) {
                            // o1 小数位 大于 o2
                            result = n1 + n2 * (t1 / t2)
                        } else {
                            // o1 小数位 小于 o2
                            result = n1 * (t2 / t1) + n2
                        }
                        return result / max
                    case '-':
                        if (t1 === t2) {
                            result = n1 - n2
                        } else if (t1 > t2) {
                            result = n1 - n2 * (t1 / t2)
                        } else {
                            result = n1 * (t2 / t1) - n2
                        }
                        return result / max
                    case '*':
                        result = (n1 * n2) / (t1 * t2)
                        return result
                    case '/':
                        result = (n1 / n2) * (t2 / t1)
                        return result
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    /deep/ p {
        margin: 0;
    }
    .student-breadcrumb{
        font-size: 14px;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 20px;
        min-height: calc(100vh - 194px);
        /*height: calc(100vh - 191px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .goodPurchase-main {
        display: flex;
        flex: 1;
        height: 1%;
        .goodPurchase-item {
            display: flex;
            flex-direction: column;
            &.item-left {
                width: 1%;
                flex: 1;
            }
            &.item-right {
                width: 45%;
                margin-left: 20px;
            }
            .item-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: -1px;
                .head-tab {
                    width: 100px;
                    box-sizing: border-box;
                    height: 36px;
                    border: 1px solid #DCDFE6;
                    text-align: center;
                    line-height: 36px;
                }
                .head-operate {
                    .el-button {
                        border-radius: 0;
                    }
                    .el-select {
                        /deep/ .el-input__inner {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 20px;
    }
    /* S 表格*/
    /deep/ .el-table {
        display: flex;
        flex-direction: column;
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #FD4446;
            border-color: #FD4446;
        }
        .el-checkbox__inner:hover {
            border-color: #FD4446;
        }
        .el-checkbox__input.is-focus .el-checkbox__inner {
            border-color: #FD4446;
        }
        .el-table__body-wrapper {
            height: 1%;
            flex: 1;
        }
        /*&.library-table {*/
        /*    .el-table__body-wrapper {*/
        /*        height: 450px;*/
        /*        overflow: auto;*/
        /*    }*/
        /*}*/
        .el-table__footer-wrapper {
            tbody {
                td {
                    background-color: #f8f8f8;
                }
            }
        }
        .good-title {
            color: #333;
            transition: all .3s;
            cursor: pointer;
            &:hover, &:focus {
                color: #FD4446;
            }
        }
    }
    /* E 表格*/
    /*分页*/
    /deep/ .pager-center {
        background: #F8F8F8;
        border: 1px solid #EBEEF5;
        border-top: 0;
        padding: 5px;
        .el-pager li {
            background: transparent;
        }
        &.el-pagination button:disabled {
            background-color: transparent;
        }
        &.el-pagination .btn-next, &.el-pagination .btn-prev {
            background: center center no-repeat transparent;
        }
    }
    .page-title {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
        .balance {
            font-size: 18px;
            color: #FD4446;
            font-family: Avenir, Helvetica, Arial, sans-serif;
            .iconfont {
                font-size: 12px;
                margin: 0 4px;
            }
        }
    }
    .bottom-btn {
        margin-top: 20px;
    }

    .mask-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        /*background: rgba(255, 255, 255, .5);*/
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        .prompt {
            color: #fff;
            font-size: 30px;
            .iconfont {
                font-size: 30px;
                margin-right: 10px;
            }
        }
    }
</style>